define("discourse/plugins/swapd-seller-success-ratio/discourse/components/seller-success-ratio", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SellerSuccessRatio extends _component.default {
    get successRatio() {
      return I18n.t("seller_success_ratio.user_card", {
        ratio: this.args.user.seller_success_ratio,
        num_transactions: this.args.user.seller_success_transactions
      });
    }
    get mustShow() {
      return this.args.user.seller_success_transactions > 0;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.mustShow}}
          {{icon 'fa-chart-line'}} {{ this.successRatio }}
        {{/if}}
      
    */
    {
      "id": "hxaR+gix",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"mustShow\"]],[[[1,\"      \"],[1,[28,[32,0],[\"fa-chart-line\"],null]],[1,\" \"],[1,[30,0,[\"successRatio\"]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/swapd-seller-success-ratio/discourse/components/seller-success-ratio.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SellerSuccessRatio;
});