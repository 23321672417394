define("discourse/plugins/swapd-seller-success-ratio/discourse/templates/connectors/user-profile-primary/discourse-user-response-times", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <SellerSuccessRatio @user={{this.model}} />
  */
  {
    "id": "tn7uvRKj",
    "block": "[[[8,[39,0],null,[[\"@user\"],[[30,0,[\"model\"]]]],null]],[],false,[\"seller-success-ratio\"]]",
    "moduleName": "discourse/plugins/swapd-seller-success-ratio/discourse/templates/connectors/user-profile-primary/discourse-user-response-times.hbs",
    "isStrictMode": false
  });
});